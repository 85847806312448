var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: _vm.checkWidth } },
    [
      _vm.step === "Statement"
        ? _c(
            "v-card",
            { attrs: { tile: "", flat: "" } },
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 py-0 pl-4 pr-0" },
                [
                  _vm._v(" New Statement "),
                  _c("v-divider", {
                    staticClass: "mx-4",
                    attrs: { inset: "", vertical: "" },
                  }),
                  _c(
                    "v-col",
                    { staticClass: "pl-0", attrs: { cols: "3" } },
                    [
                      _c("v-subheader", { staticClass: "success--text" }, [
                        _vm._v(" Statement Information "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pl-0" },
                    [_c("v-icon", [_vm._v("mdi-transfer-right")])],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pl-0", attrs: { cols: "2" } },
                    [_c("v-subheader", [_vm._v(" Adjustments ")])],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pl-0" },
                    [_c("v-icon", [_vm._v("mdi-transfer-right")])],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pl-0", attrs: { cols: "3" } },
                    [_c("v-subheader", [_vm._v(" Generate Statement ")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pb-0" },
                [
                  _c(
                    "v-form",
                    { ref: "form", attrs: { "lazy-validation": "" } },
                    [
                      _c(
                        "v-container",
                        { staticClass: "mx-0 px-0 mt-0" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Name*",
                                      required: "",
                                      rules: [
                                        function (v) {
                                          return !!v || "Name is required"
                                        },
                                        _vm.validateText,
                                      ],
                                      "hide-details": "auto",
                                      outlined: "",
                                      dense: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.statement.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.statement, "name", $$v)
                                      },
                                      expression: "statement.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: "Responsible Party*",
                                      items: _vm.responsibleParties,
                                      "menu-props": { offsetY: true },
                                      "item-text": "name",
                                      "item-value": "id",
                                      rules: [
                                        function (v) {
                                          return (
                                            !!v ||
                                            "Responsible party is required"
                                          )
                                        },
                                      ],
                                      outlined: "",
                                      "hide-details": "auto",
                                      dense: "",
                                    },
                                    model: {
                                      value: _vm.statement.responsible_party_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.statement,
                                          "responsible_party_id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "statement.responsible_party_id",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "primary--text",
                                      attrs: { text: "", depressed: "" },
                                      on: { click: _vm.addNote },
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("mdi-plus"),
                                      ]),
                                      _vm._v("Add Note "),
                                    ],
                                    1
                                  ),
                                  _vm.noteStatus
                                    ? _c("v-textarea", {
                                        attrs: {
                                          label: "Optional Note",
                                          rules: [
                                            function (v) {
                                              return (
                                                (v || "").length <= 2000 ||
                                                "Note must be 2000 characters or less"
                                              )
                                            },
                                          ],
                                          outlined: "",
                                          "hide-details": "auto",
                                          dense: "",
                                        },
                                        model: {
                                          value: _vm.statement.note,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.statement, "note", $$v)
                                          },
                                          expression: "statement.note",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "auto" } }, [
                        _c("small", [_vm._v("*Required field")]),
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "ml-auto", attrs: { cols: "auto" } },
                        [
                          _vm.step === "Statement"
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "grey darken-2" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("update:value", false)
                                    },
                                  },
                                },
                                [_vm._v(" Cancel ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "success",
                                loading: _vm.loading,
                                disabled: !_vm.selected.length,
                              },
                              on: { click: _vm.finishUp },
                            },
                            [_vm._v(" Continue ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("v-divider"),
      _vm.step === "Statement"
        ? _c(
            "v-card",
            { attrs: { tile: "", flat: "" } },
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "", color: "fill", height: "80px" } },
                [
                  _c("ToggleExpanded", { attrs: { refs: _vm.$refs } }),
                  _c("v-divider", {
                    staticClass: "mx-4",
                    attrs: { vertical: "", inset: "" },
                  }),
                  _c(
                    "v-row",
                    { staticClass: "ma-0 pa-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "3" } },
                        [
                          _c("v-autocomplete", {
                            staticStyle: {
                              height: "40px",
                              "border-top-right-radius": "0",
                              "border-bottom-right-radius": "0",
                            },
                            attrs: {
                              dense: "",
                              solo: "",
                              flat: "",
                              outlined: "",
                              placeholder: "Search By",
                              items: _vm.searchParams,
                              "item-text": "description",
                              "return-object": "",
                              "menu-props": { maxHeight: 260, rounded: "" },
                            },
                            model: {
                              value: _vm.search.key,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "key", $$v)
                              },
                              expression: "search.key",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "6" } },
                        [
                          [
                            _c("v-text-field", {
                              staticStyle: {
                                height: "40px",
                                "border-radius": "0",
                              },
                              attrs: {
                                dense: "",
                                solo: "",
                                flat: "",
                                outlined: "",
                                placeholder: _vm.search.key
                                  ? "Search By " + _vm.search.key.description
                                  : "Select Search Field",
                                disabled: !_vm.search.key,
                                clearable: "",
                              },
                              on: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.searchBatches.apply(
                                    null,
                                    arguments
                                  )
                                },
                                "click:clear": _vm.clearSearch,
                              },
                              model: {
                                value: _vm.search.value,
                                callback: function ($$v) {
                                  _vm.$set(_vm.search, "value", $$v)
                                },
                                expression: "search.value",
                              },
                            }),
                          ],
                        ],
                        2
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "1" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: {
                                height: "40px",
                                "border-left": "0px",
                                "border-top-left-radius": "0px",
                                "border-bottom-left-radius": "0px",
                                border: "1px solid #9e9e9e",
                              },
                              attrs: {
                                dense: "",
                                depressed: "",
                                loading: _vm.loading,
                                disabled: !_vm.search.key || !_vm.search.value,
                                color: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.searchBatches()
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v(_vm._s("mdi-magnify"))])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-data-table", {
                attrs: {
                  dense: "",
                  "items-per-page": _vm.pageSize,
                  headers: _vm.headers,
                  "group-by": "post_id",
                  height: "280px",
                  items: _vm.batches,
                  loading: _vm.loading,
                  "show-select": "",
                  "single-select": "",
                  search: _vm.filter,
                  "item-key": "post_id",
                  "fixed-header": "",
                  "hide-default-footer": "",
                },
                on: {
                  "update:items": function ($event) {
                    _vm.batches = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.responsible_party_id",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          item &&
                          _vm.responsibleParties &&
                          _vm.responsibleParties.length > 0
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getResponsiblePartyName(
                                        item.responsible_party_id
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "group.header",
                      fn: function (ref) {
                        var group = ref.group
                        var headers = ref.headers
                        var toggle = ref.toggle
                        var isOpen = ref.isOpen
                        return [
                          _c(
                            "td",
                            { attrs: { colspan: headers.length } },
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      ref: group,
                                      attrs: {
                                        small: "",
                                        icon: "",
                                        "data-open": isOpen,
                                      },
                                      on: { click: toggle },
                                    },
                                    [
                                      isOpen
                                        ? _c("v-icon", [
                                            _vm._v("mdi-chevron-up"),
                                          ])
                                        : _c("v-icon", [
                                            _vm._v("mdi-chevron-down"),
                                          ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { dense: "", cols: "9" } },
                                    [
                                      _vm._v(
                                        " Post Group: " +
                                          _vm._s(
                                            group.substring(0, 8).toUpperCase()
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("v-col", {
                                    attrs: { justify: "end", cols: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "v-toolbar",
                            {
                              attrs: { dense: "", flat: "", color: "#fafafa" },
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center", justify: "end" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticStyle: { "max-width": "150px" },
                                      attrs: { cols: "2" },
                                    },
                                    [
                                      _c("v-select", {
                                        staticClass: "mt-8",
                                        attrs: {
                                          dense: "",
                                          items: _vm.pageSizes,
                                          label: "Items Per Page",
                                          "menu-props": {
                                            top: true,
                                            offsetY: true,
                                            maxHeight: 500,
                                          },
                                        },
                                        model: {
                                          value: _vm.pageSize,
                                          callback: function ($$v) {
                                            _vm.pageSize = $$v
                                          },
                                          expression: "pageSize",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mx-2",
                                      attrs: {
                                        icon: "",
                                        large: "",
                                        disabled: _vm.disablePrevious,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.previous()
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-left"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c("small", [
                                    _vm._v("Page " + _vm._s(_vm.page)),
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mx-2",
                                      attrs: {
                                        icon: "",
                                        large: "",
                                        disabled: _vm.disableNext,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.next()
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-right"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.selected,
                  callback: function ($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.step === "Adjustments"
        ? _c(
            "v-card",
            { attrs: { flat: "", tile: "" } },
            [
              _c(
                "v-card-title",
                { staticClass: "ma-0 py-0 pl-4 pr-0" },
                [
                  _vm._v(" Optional Adjustments "),
                  _c("v-divider", {
                    staticClass: "mx-4",
                    attrs: { inset: "", vertical: "" },
                  }),
                  _c(
                    "v-col",
                    { staticClass: "pl-0", attrs: { cols: "3" } },
                    [_c("v-subheader", [_vm._v(" Statement Information ")])],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pl-0" },
                    [_c("v-icon", [_vm._v("mdi-transfer-right")])],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pl-0", attrs: { cols: "2" } },
                    [
                      _c("v-subheader", { staticClass: "success--text" }, [
                        _vm._v(" Adjustments "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pl-0" },
                    [_c("v-icon", [_vm._v("mdi-transfer-right")])],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pl-0", attrs: { cols: "3" } },
                    [_c("v-subheader", [_vm._v(" Generate Statement ")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pt-0" },
                [
                  _c(
                    "v-form",
                    { ref: "form", attrs: { "lazy-validation": "" } },
                    [
                      _c(
                        "v-container",
                        {
                          staticClass: "mx-0 px-0 mt-0 pt-0",
                          attrs: { fluid: "" },
                        },
                        [
                          _c("v-subheader", { staticClass: "pl-0 pt-0 mt-0" }, [
                            _vm._v(" Optional Party Selection "),
                          ]),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.modalities,
                                      outlined: "",
                                      "hide-details": "auto",
                                      dense: "",
                                      tabindex: "0",
                                    },
                                    on: { change: _vm.checkAdjustmentParties },
                                    model: {
                                      value: _vm.adjustment.modality,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.adjustment,
                                          "modality",
                                          $$v
                                        )
                                      },
                                      expression: "adjustment.modality",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: "Party Type",
                                      items: _vm.party_types,
                                      "item-text": "kv_name",
                                      "item-value": "kv_constant",
                                      outlined: "",
                                      "hide-details": "auto",
                                      dense: "",
                                      tabindex: "0",
                                    },
                                    on: { change: _vm.checkAdjustmentParties },
                                    model: {
                                      value: _vm.adjustment.party_type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.adjustment,
                                          "party_type",
                                          $$v
                                        )
                                      },
                                      expression: "adjustment.party_type",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.adjustment.modality !== "ALL"
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "7" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          label: _vm.activePartyOption.label,
                                          multiple: "",
                                          outlined: "",
                                          items: _vm.activePartyOption.items,
                                          "small-chips": "",
                                          "item-text":
                                            _vm.activePartyOption.text,
                                          "item-value":
                                            _vm.activePartyOption.value,
                                          "hide-details": "auto",
                                          loading: _vm.loadingParties,
                                          dense: "",
                                          tabindex: "0",
                                        },
                                        model: {
                                          value: _vm.adjustment.party_ids,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.adjustment,
                                              "party_ids",
                                              $$v
                                            )
                                          },
                                          expression: "adjustment.party_ids",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("v-subheader", { staticClass: "pl-0 pt-0 mt-4" }, [
                            _vm._v(" Adjustment Selection "),
                          ]),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: "Adjustment Type*",
                                      items: _vm.validAdjustmentTypes,
                                      "menu-props": { offsetY: true },
                                      "item-text": "description",
                                      "item-value": "id",
                                      outlined: "",
                                      "hide-details": "auto",
                                      dense: "",
                                      tabindex: "0",
                                    },
                                    model: {
                                      value: _vm.adjustment.adjustment_type_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.adjustment,
                                          "adjustment_type_id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "adjustment.adjustment_type_id",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Description*",
                                      required: "",
                                      "hide-details": "auto",
                                      outlined: "",
                                      dense: "",
                                      tabindex: "0",
                                    },
                                    model: {
                                      value: _vm.adjustment.description,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.adjustment,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "adjustment.description",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: "Mode*",
                                      items: _vm.types,
                                      outlined: "",
                                      "hide-details": "auto",
                                      dense: "",
                                      tabindex: "0",
                                    },
                                    model: {
                                      value: _vm.type,
                                      callback: function ($$v) {
                                        _vm.type = $$v
                                      },
                                      expression: "type",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Amount*",
                                      dense: "",
                                      "single-line": "",
                                      outlined: "",
                                      "background-color": "input",
                                      "hide-details": "",
                                      tabindex: "0",
                                      messages: "Click Icon for %",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend-inner",
                                          fn: function () {
                                            return [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.switchAmount()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.amountIcon) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2923901076
                                    ),
                                    model: {
                                      value: _vm.adjustment.amount,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.adjustment, "amount", $$v)
                                      },
                                      expression: "adjustment.amount",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "primary--text",
                                      attrs: {
                                        text: "",
                                        depressed: "",
                                        tabindex: "0",
                                      },
                                      on: { click: _vm.addNote },
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("mdi-plus"),
                                      ]),
                                      _vm._v("Add Note "),
                                    ],
                                    1
                                  ),
                                  _vm.noteStatus
                                    ? _c("v-text-field", {
                                        attrs: {
                                          label: "Optional Note",
                                          rules: [
                                            function (v) {
                                              return (
                                                (v || "").length <= 256 ||
                                                "Note must be 256 characters or less"
                                              )
                                            },
                                          ],
                                          height: "80px",
                                          outlined: "",
                                          dense: "",
                                          tabindex: "0",
                                        },
                                        model: {
                                          value: _vm.adjustment.note,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.adjustment,
                                              "note",
                                              $$v
                                            )
                                          },
                                          expression: "adjustment.note",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "accent",
                                loading: _vm.loading,
                                disabled: _vm.loading,
                                tabindex: "1",
                              },
                              on: { click: _vm.pushAdjustment },
                            },
                            [
                              _c("v-icon", [_vm._v("mdi-plus")]),
                              _vm._v(" Add "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "auto" } }, [
                        _c("small", [_vm._v("*Required field")]),
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "ml-auto", attrs: { cols: "auto" } },
                        [
                          _vm.step === "Adjustments"
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "grey darken-2" },
                                  on: { click: _vm.cancelStatement },
                                },
                                [_vm._v(" Cancel ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "success",
                                loading: _vm.generating,
                                disabled: _vm.generating,
                              },
                              on: { click: _vm.generate },
                            },
                            [_vm._v(" Generate Statement ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("v-divider"),
      _vm.step === "Adjustments"
        ? _c(
            "v-card",
            { attrs: { color: "#fafafa" } },
            [
              _c("v-data-table", {
                directives: [
                  {
                    name: "ripple",
                    rawName: "v-ripple",
                    value: false,
                    expression: "false",
                  },
                ],
                staticClass: "table",
                attrs: {
                  items: _vm.adjustments,
                  dense: "",
                  headers: _vm.headersAdj,
                  height: "200px",
                  loading: _vm.loading,
                  "fixed-header": "",
                  search: _vm.filter,
                },
                on: {
                  "update:items": function ($event) {
                    _vm.adjustments = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.actionsAdj",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteAdjustment(item)
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-trash-can")])],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.adjustment_type",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formatAdjustmentType(
                                  item.adjustment_type_id
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "item.mode",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          item.amount < 0
                            ? _c("span", [_vm._v("DEBIT")])
                            : item.amount > 0
                            ? _c("span", [_vm._v("CREDIT")])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "item.amount",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          !item.is_percent
                            ? _c("span", [
                                _vm._v(
                                  "$" +
                                    _vm._s(
                                      _vm.formatCurrencyDisplay(item.amount)
                                    )
                                ),
                              ])
                            : item.is_percent
                            ? _c("span", [_vm._v("N/A")])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "item.percent",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          item.is_percent
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatCurrencyDisplay(item.amount)
                                  ) + "%"
                                ),
                              ])
                            : !item.is_percent
                            ? _c("span", [_vm._v("N/A")])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "item.party",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(item.modality) +
                                " " +
                                _vm._s(item.party_type)
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.step === "Finish"
        ? _c("v-card", { attrs: { color: "#fafafa" } }, [
            _c(
              "section",
              { staticClass: "ma-0 pa-6" },
              [
                _c(
                  "v-row",
                  { attrs: { justify: "center" } },
                  [
                    _c(
                      "v-icon",
                      { attrs: { "x-large": "", color: "success" } },
                      [_vm._v("mdi-checkbox-marked-circle-outline")]
                    ),
                  ],
                  1
                ),
                _c("v-row", { attrs: { justify: "center" } }, [
                  _c("span", { staticClass: "pa-4" }, [
                    _vm._v(" Statement Generating! "),
                  ]),
                ]),
                _c(
                  "v-row",
                  { attrs: { justify: "center" } },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", color: "grey darken-2" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("closeModal", true)
                          },
                        },
                      },
                      [_vm._v(" Return to Statements ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }